.container {
    background: linear-gradient(to bottom, rgba(18, 120, 141, .75) 15%, rgba(112,173,168, 0) 90%), url('./sky-background.jpg');
    background-size: cover;
    background-color: #10788D;
    height: 100vh;
    position:relative;
}

.hero {
    text-align:center;
    padding-top:2rem;
    display:flex;
    flex-direction: column;
}

.hero h1 {
    font-family: "AbeeZee", sans-serif;
    font-size: 3rem;
    letter-spacing: 4%;
    color: white;
}

.hero p {
    color:white;
    font-size: 1.4rem;
    width: 75%;
    margin: 0 auto;
}

.button-container {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 3.8rem auto;
}

.store-link {
    height: 52px;
}

.coming-soon {
    text-align:center;
    margin: 2rem auto;
    color: white;
    font-style: italic;
}

.character {
    position:absolute;
    left:0;
    bottom:0;
    margin: 0 auto;
    z-index:1 !important;
}

.character img {
    width: 100vw;
}

@media screen and (min-width: 480px) {
    .character img {
        max-height: 25vh;
        width: auto;
    }
}

.footer {
    z-index:3 !important;
    background-color: #1F8091;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
}

.footer-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 24px;
    gap: 10px;
}

.footer a {
    color: white;
    text-decoration: none;
}